import { createAuth0 } from '@auth0/auth0-vue';

export const auth = createAuth0({
    domain: import.meta.env.VITE_AUTH_DOMAIN,
    clientId: import.meta.env.VITE_ACCOUNT_CENTRAL_OIDC_CLIENT_ID,
    issuer: import.meta.env.VITE_AUTH_JWT_ISSUER,
    useRefreshTokens: true,
    authorizationParams: {
        redirect_uri: window.location.origin,
        scope: 'openid profile email security_profile keap_profile',
    },
}, {});
