export const FF_6271_EMAIL_OVERAGE_FEE = 'rocinante.plat.6271.email.overage.fee';
export const FF_52356_SANDBOX_CONTACT_IMPORT_ALERT = 'fs.52356.sandbox.contact.import.alert';
export const FF_CHARGEBEE_ENABLE_EDIT_PORTAL_BUTTON = 'plat.5313.enable.chargebee.edit.button';
export const FF_SHOW_MESSAGE_BUNDLES = 'rocinante.plat.7264.message.bundles';
export const FF_PLAT_4497_IN_APP_CANCEL_REQUEST = 'rocinante.plat.4497.in.app.cancel.request';
export const FF_PLAT_7490_NAV_APPS = 'rocinante.plat.7490.account.central.nav.apps';
export const FF_PLAT_7488_NAV_KEAP_ID = 'rocinante.plat.7488.account.central.nav.keap.id';
export const FF_PLAT_7487_NAV_LEARN = 'rocinante.plat.7487.account.central.nav.learn';
export const FF_PLAT_7489_NAV_SECURITY = 'rocinante.plat.7489.account.central.nav.security';
export const FF_PLAT_7537_PRO_SERVICES = 'rocinante.plat.7537.account.central.pro.services';
export const FF_PLAT_7537_PRO_SERVICES_AB_TEST = 'rocinante.plat.7537.account.central.pro.services.ab.test';
export const FF_PLAT_7508_ENABLE_AC_QUICK_ADD = 'rocinante.plat.7508.ac.quick.add';
export const FF_PLAT_7827_ENABLE_USERS_QUICK_ADD = 'rocinante.plat.7827.ac.quick.add.users';
